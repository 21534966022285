import useSetTitle from "../../components/helpers/useSetTitle";
import { useGetPageQuery } from "../../state/features/api/apiSlice";
import { useMemo } from "react";
import { Container, Spinner } from "react-bootstrap";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

function MarkdownText(props: {
    page: string,
    heading: string,
    style?: React.CSSProperties,
}) {
    useSetTitle(props.heading);

    const { data, error, isLoading } = useGetPageQuery(props.page);

    const content = useMemo(
        () => {
            if (!isLoading && data != null && !error) {
                return <Container>
                    <div className="navbar-spacer" />
                    <Markdown remarkPlugins={[remarkGfm]} className="my-5">
                        {data}
                    </Markdown>
                </Container>
            }
            // else if (error)
            //     return <>{t_static_about('company-name-initials')}</>;
            else
                return <Spinner animation="grow" />;
        },
        [data, error, isLoading]
    );

    return (
        <>
            {content}
        </>
    );
};

export default MarkdownText;