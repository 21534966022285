import { configureStore } from '@reduxjs/toolkit'
import { apiSlice } from './features/api/apiSlice'

export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredPaths: ['api.queries'],
            },
        }).concat(apiSlice.middleware),
})