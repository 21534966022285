import React, { Suspense } from 'react';
import Navigation from './components/Navigation/Navigation';
import { Route, Routes } from "react-router-dom";
import Loading from './components/Loading/Loading';
import Footer from './components/Footer/Footer';
import { useTranslation } from 'react-i18next';
import MarkdownText from './pages/MarkdownText/MarkdownText';

const LandingPage = React.lazy(() => import('./pages/LandingPage/LandingPage'));
const PrivacyPolicy = React.lazy(() => import('./pages/Legal/PrivacyPolicy'));
const Imprint = React.lazy(() => import('./pages/Legal/Imprint'));
const SeeBelow = React.lazy(() => import('./pages/SeeBelow/SeeBelow'));

function App() {
  const { t: t_navigation } = useTranslation("translation", { keyPrefix: "navigation" })

  const { t: t_common } = useTranslation("translation", { keyPrefix: "common" });

  return (
    <>
      <Navigation />
      {/* Content Container (will take at least the entire viewport) */}
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        {/* Container for the main content */}
        <div style={{ width: "100%" }} className='d-flex justify-content-center align-items-center flex-grow-1'>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route index path="/" element={<LandingPage />} />
              <Route path={"/" + t_navigation("about")} element={<SeeBelow heading={t_common("about")} scrollToId="footer-about-us"/>} />
              <Route path={"/" + t_navigation("contact")} element={<SeeBelow heading={t_common("contact")} scrollToId="footer-contact-info"/>} />
              <Route path={"/" + t_navigation("privacy-policy")} element={<PrivacyPolicy />} />
              <Route path={"/" + t_navigation("imprint")} element={<Imprint />} />
              <Route path={"/test"} element={<MarkdownText page="hello_world.md" heading='Test' />} />
              <Route path={"/example"} element={<MarkdownText page="example.md" heading='Beispiel' />} />
            </Routes>
          </Suspense>
        </div>
        {/* Container for the footer */}
        <Footer />
      </div>

    </>

  );
}

export default App;
