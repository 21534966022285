import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

function FooterBottomLine() {
    const { t: t_static } = useTranslation("static");
    const { t: t_translation } = useTranslation("translation");

    return (
        <div className="border-top">
            <Container className="d-flex flex-column flex-md-row-reverse justify-content-between align-items-center">
                <ul className="navbar navbar-nav flex-row clear list-unstyled">
                    <li>
                        <NavLink className="nav-link" to={"/" + t_translation("navigation.privacy-policy")}>
                            {t_translation("common.privacy-policy")}
                        </NavLink>
                    </li>
                    <li className="ms-3">
                        <NavLink className="nav-link" to={"/" + t_translation("navigation.imprint")}>
                            {t_translation("common.imprint")}
                        </NavLink>
                    </li>
                </ul>
                <div className="d-flex flex-wrap gap-2 py-3 justify-content-center">
                    <div>{t_static("copyright")} {(new Date().getFullYear())},</div>
                    <div>{t_static("credits")}</div>
                </div>
            </Container>
        </div>
    );
}

export default FooterBottomLine