import { Container, Nav, Navbar, Spinner } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useGetFileQuery } from "../../state/features/api/apiSlice";
import { useMemo } from "react";


import "external-svg-loader";
import { useTranslation } from "react-i18next";

function Navigation(props: {
    style?: React.CSSProperties,
}) {
    // use translation Hook
    const { t: t_common } = useTranslation('translation', { keyPrefix: 'common' });
    const { t: t_static_about } = useTranslation('static', { keyPrefix: 'name' });
    const { t: t_navigation } = useTranslation("translation", { keyPrefix: "navigation" })

    // fetch the logo
    const { data, error, isLoading } = useGetFileQuery("Logo.svg");

    // create image component (prevent unnecessary regeneration)
    const Logo = useMemo(
        () => {
            if (!isLoading && data != null && !error)
                return <svg data-src={data.FileUrl} data-cache="disabled" fill="currentColor" style={{ color: 'inherit' }} className="logo-svg" />;
            else if (error)
                return <>{t_static_about('company-name-initials')}</>;
            else
                return <Spinner animation="grow" />;
        },
        [data, error, isLoading, t_static_about]
    );

    return (
        <Navbar expand="lg" fixed="top" className="py-3 navbar-hide-on-scroll">
            < Container >
                <Navbar.Brand>
                    <NavLink to="/" className="logo">
                        {Logo}
                    </NavLink>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <NavLink className="nav-link" to={"/" + t_navigation("about")}>
                            {t_common('about')}
                        </NavLink>
                        <NavLink className="nav-link" to={"/" + t_navigation("contact")}>
                            {t_common("contact")}
                        </NavLink>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </ Navbar>
    );
}

export default Navigation;