import { Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { useGetFileQuery } from "../../state/features/api/apiSlice";
import { Whatsapp, Instagram } from "react-bootstrap-icons";

function FooterAboutUs(props: { id?: string }) {
    const { t: t_static } = useTranslation("static");
    const { t: t_translation } = useTranslation("translation");

    // fetch the logo
    const { data, error, isLoading } = useGetFileQuery("Logo.svg");

    // create image component (prevent unnecessary regeneration)
    const Logo = useMemo(
        () => {
            if (!isLoading && data != null && !error) {
                return (
                    <div className="mt-2 mb-3 me-5">
                        <svg data-src={data.FileUrl} data-cache="disabled" fill="currentColor" style={{ color: "inherit", width: "6rem" }} />
                    </div>
                );
            }
            else if (error)
                return <></>;
            else
                return (
                    <div className="mt-2 mb-3 me-5">
                        <Spinner animation="grow" />
                    </div>
                );
        },
        [data, error, isLoading]
    );


    return (
        <div className="d-flex flex-column flex-lg-row align-items-start" id={props.id}>
            {Logo}
            <div className="flex-grow-1">
                <h2>{t_static("name.company-name-long")}</h2>
                <p>{t_translation("footer.about-us")}</p>
                <ul className="d-flex gap-2 list-unstyled flex-row">
                    <li>
                        <Button href={"https://wa.me/" + t_static("contact.whatsapp")} target="_blank" rel="noreferrer" variant="light" className="py-2 px-2"  >
                            <div className="d-flex">
                                <Whatsapp height="1.25em" width="1.25em" className="" />
                            </div>
                        </Button>
                    </li>
                    <li>
                        <Button href={"https://www.instagram.com/" + t_static("contact.instagram")} target="_blank" rel="noreferrer" variant="light" className="py-2 px-2"  >
                            <div className="d-flex">
                                <Instagram height="1.25em" width="1.25em" className="" />
                            </div>
                        </Button>
                    </li>
                    {/*<li>
                        <Button href={"https://www.facebook.com/" + t_static("contact.facebook")} target="_blank" rel="noreferrer" variant="light" className="py-2 px-2"  >
                            <div className="d-flex">
                                <Facebook height="1.25em" width="1.25em" className="" />
                            </div>
                        </Button>
                    </li>*/}
                </ul>
            </div>
        </div>
    );
}

export default FooterAboutUs;