import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { HouseFill, TelephoneFill, EnvelopeFill, PrinterFill } from "react-bootstrap-icons";

function FooterContactInfo(props: { id?: string }) {
    const { t: t_static, i18n: i18n_static } = useTranslation("static");
    const { t: t_translation } = useTranslation("translation");

    return (
        <div className="text-nowrap" id={props.id}>
            <h2>{t_translation("common.contact")}</h2>
            <Table className="table-borderless" >
                <tbody>
                    <tr>
                        <td><HouseFill /></td>
                        <td><a className="link-body-emphasis link-underline-opacity-0 link-underline-opacity-75-hover" target="_blank" rel="noreferrer" href={"https://maps.app.goo.gl/" + t_static("contact.address.maps-code")}>{t_static("contact.address.street")}<br />{t_static("contact.address.zip-code") + " " + t_static("contact.address.city")}</a></td>
                    </tr>
                    <tr>
                        <td><EnvelopeFill /></td>
                        <td><a className="link-body-emphasis link-underline-opacity-0 link-underline-opacity-75-hover" href={"mailto:" + t_static("contact.e-mail")}>{t_static("contact.e-mail")}</a></td>
                    </tr>
                    <tr>
                        <td><TelephoneFill /></td>
                        <td><a className="link-body-emphasis link-underline-opacity-0 link-underline-opacity-75-hover" href={"tel:" + t_static("contact.telephone")}>{t_static("contact.telephone-fancy")}</a></td>
                    </tr>
                    {/* check if any facsimile information is provided if not don't show it */}
                    {i18n_static.exists("contact.facsimile") && t_static("contact.facsimile") !== "" ? (
                        <tr>
                            <td><PrinterFill /></td>
                            <td><a className="link-body-emphasis link-underline-opacity-0 link-underline-opacity-75-hover" href={"fax:" + t_static("contact.facsimile")}>{t_static("contact.facsimile-fancy")}</a></td>
                        </tr>
                    ) : (
                        <></>
                    )}
                </tbody>
            </Table>
        </div>
    );
}

export default FooterContactInfo;