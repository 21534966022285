import React from 'react';
import ReactDOM from 'react-dom/client';
import './style/index.scss';
// import './style/notheme.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './state/store'
import { Provider } from 'react-redux'
import { BrowserRouter } from "react-router-dom";

// import i18n (needs to be bundled ;)) 
import i18n from './i18n';

// set lang field in html correctly for selected language
i18n.on('languageChanged', (lng) => { document.documentElement.setAttribute('lang', lng); })

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
