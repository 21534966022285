import { Spinner } from "react-bootstrap";

function Loading(props: {}) {
    return <div>
        <div className="navbar-spacer" />
        <div className="py-5" >
            <Spinner />
        </div>
    </div>;
}

export default Loading;