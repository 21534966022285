import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function useSetTitle(title: string) {
    const { t: t_static } = useTranslation("static");

    useEffect(() => {
        document.title = t_static("name.company-name-initials") + " - " + title;
    }, [t_static, title]);
}

export default useSetTitle;