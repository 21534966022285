import { Container } from "react-bootstrap";
import FooterContactInfo from "./FooterContactInfo";
import FooterBottomLine from "./FooterBottomLine";
import FooterAboutUs from "./FooterAboutUs";

function Footer(props: {}) {
    return (
        <footer>
            <div className="border-top">
                <Container >
                    <div className="d-flex flex-column flex-lg-row py-3 gap-5">
                        <div className="flex-grow-1">
                            <FooterAboutUs id="footer-about-us" />
                        </div>
                        <FooterContactInfo id="footer-contact-info" />
                    </div>
                </Container>
            </div >

            <FooterBottomLine />
        </footer >
    );
}

export default Footer;